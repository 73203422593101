import moment from 'moment';
import dates from './dates.json';
import { Fragment, h } from 'preact';

const Footer = () => (
  <Fragment>
    <section className="submit-section">
      <div className="background-scene-background"></div>
      <div className="submit-content">
        {moment().isBefore(dates['proposals-released'].toString())
          ? 'Submit your idea to get started.'
          : 'Submissions are closed now.'}
      </div>
      <a
        href={
          moment().isBefore(dates['proposals-released'].toString())
            ? '/register'
            : '/results'
        }
        className="woc-button"
      >
        <button id="register-button" type="button">
          {moment().isBefore(dates['proposals-released'].toString())
            ? 'Register'
            : 'Results'}
        </button>
      </a>
    </section>

    <footer className="footer">
      <div className="sds-logo">
        <a href="https://sdslabs.co/" target="_blank">
          <img src="../../assets/images/sds-logo.svg" className="footer-logo" />
        </a>
      </div>
      <a
        target="_blank"
        href="https://discord.gg/mAAkdQgs4z"
        className="footer-link"
      >
        SDSLabs Discord
      </a>
      <div className="footer-social-icons-container">
        <a href="https://www.facebook.com/SDSLabs/">
          <img
            src="../../assets/images/facebook.svg"
            className="footer-social-icon"
          />
        </a>
        <a href="https://www.instagram.com/sdslabs/">
          <img
            src="../../assets/images/instagram.svg"
            className="footer-social-icon"
          />
        </a>
        <a href="https://github.com/sdslabs/">
          <img
            src="../../assets/images/github.svg"
            className="footer-social-icon"
          />
        </a>
        <a href="https://twitter.com/sdslabs">
          <img
            src="../../assets/images/twitter.svg"
            className="footer-social-icon"
          />
        </a>
      </div>
    </footer>
  </Fragment>
);

export default Footer;
