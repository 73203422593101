import '../styles/main.scss';

import { h } from 'preact';
import { Router } from 'preact-router';

import Home from './home';
import { CheckRegister, Register } from './register';
import Results from './results';

const URLs = {
  FRONTEND_URL : "https://woc.sdslabs.co",
  BACKEND_URL : "https://woc-backend.sdslabs.co",
  FRONTEND_URL_TEST : "http://localhost:8080",
  BACKEND_URL_TEST : "http://localhost:8000"
}

export { URLs }

const App = () => (
  <div id="app">
    <Router>
      <Home path="/" />
      <Register path="/register" />
      <CheckRegister path="/checksubmission" />
      <Results path="/results" />
    </Router>
  </div>
);

export default App;
