/* eslint-disable no-alert */
import Axios from 'axios';
import { Fragment, h } from 'preact';
import { useState } from 'preact/hooks';
import { URLs } from '../components/app';

function smoothScroll(event, id) {
  event.preventDefault();
  let url = new URL(window.location.href);
  if(!url.href.endsWith('/')){
    window.location.href = '/'
  }
  document.getElementById(id).scrollIntoView({behavior: "smooth"});
}


function openmenu(){
  var x = document.getElementById("navopt");
  if (x.style.display == "flex") {
    x.style.display = "none";
  } else {
    x.style.display = "flex";
  }
}

const Register = () => {
  const registerUrl = URLs.BACKEND_URL + '/woc/register';
  const [data, setData] = useState({
    name: '',
    email: '',
    phone: '',
    branch: '',
    enroll: '',
    link: '',
    devdes: '',
    github: '',
    dribbble: '',
    behance: '',
    others: '',
  });

  function handle(e) {
    const newData = { ...data };
    newData[e.target.id] = e.target.value;
    setData(newData);
  }

  // eslint-disable-next-line consistent-return
  async function submit(e) {
    e.preventDefault();
    try {
      const send = {
        name: data.name,
        email: data.email,
        phone: data.phone,
        branch: data.branch,
        enroll: data.enroll,
        link: data.link,
        devdes: data.devdes,
        github: data.github,
        dribbble: data.dribbble,
        behance: data.behance,
        others: data.others,
      };
      const response = await Axios.post(registerUrl, send);
      if (response.status >= 200 && response.status < 300) {
        alert('User registered successfully');
        window.location.href = URLs.FRONTEND_URL+'/checksubmission';
      } else {
        alert('Error in form submission');
      }
    } catch (error) {
      alert(
        'Error in form submission. Please check if your details are unique and correct.'
      );
    }
  }

  return (
    <Fragment>
      <ul className="nav-bar scrolled shadow" id="navbar-register">
        <li className="sds-logo-container">
          <a
            href="https://sdslabs.co/"
            target="_blank"
            className="js-scroll-trigger"
          >
            <img src="../../assets/images/sds-logo.svg" className="sds-logo" />
            <div className="sds-logo-colored-container">
              <img
                src="../../assets/images/sds-logo-colored.svg"
                className="sds-logo-colored"
              />
              <hr className="sds-logo-colored-vertical-line" />
              <span className="sds-logo-colored-text">WoC</span>
            </div>
          </a>
        </li>

            {/* This is where navbar is coded */}
          {/* This is where hamburger menu is coded */}
          <button className="hamburger-menu" onClick={openmenu}>
              <svg id="hamburger" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 3C1.36739 3 1.24021 3.05268 1.14645 3.14645C1.05268 3.24021 1 3.36739 1 3.5C1 3.63261 1.05268 3.75979 1.14645 3.85355C1.24021 3.94732 1.36739 4 1.5 4H13.5C13.6326 4 13.7598 3.94732 13.8536 3.85355C13.9473 3.75979 14 3.63261 14 3.5C14 3.36739 13.9473 3.24021 13.8536 3.14645C13.7598 3.05268 13.6326 3 13.5 3H1.5ZM1 7.5C1 7.36739 1.05268 7.24021 1.14645 7.14645C1.24021 7.05268 1.36739 7 1.5 7H13.5C13.6326 7 13.7598 7.05268 13.8536 7.14645C13.9473 7.24021 14 7.36739 14 7.5C14 7.63261 13.9473 7.75979 13.8536 7.85355C13.7598 7.94732 13.6326 8 13.5 8H1.5C1.36739 8 1.24021 7.94732 1.14645 7.85355C1.05268 7.75979 1 7.63261 1 7.5ZM1 11.5C1 11.3674 1.05268 11.2402 1.14645 11.1464C1.24021 11.0527 1.36739 11 1.5 11H13.5C13.6326 11 13.7598 11.0527 13.8536 11.1464C13.9473 11.2402 14 11.3674 14 11.5C14 11.6326 13.9473 11.7598 13.8536 11.8536C13.7598 11.9473 13.6326 12 13.5 12H1.5C1.36739 12 1.24021 11.9473 1.14645 11.8536C1.05268 11.7598 1 11.6326 1 11.5Z" fill="white"/>
        </svg>
              </button>
        {/* hamburger icon */}
    
        <div className="nav-div" id="navopt">
          <li className="nav-items">
            <a href="/#about" className="nav-links js-scroll-trigger" onClick={(event) => smoothScroll(event, "about")}>
              About
            </a>
          </li>
          <li className="nav-items">
            <a href="#process" className="nav-links js-scroll-trigger" onClick={(event) => smoothScroll(event, "process")}>
              Process
            </a>
          </li>
          <li className="nav-items">
            <a href="#timeline" className="nav-links js-scroll-trigger" onClick={(event) => smoothScroll(event, "timeline")}>
              Timeline
            </a>
          </li>
          <li className="nav-items">
            <a href="#guidelines" className="nav-links js-scroll-trigger" onClick={(event) => smoothScroll(event, "guidelines")}>
              Guidelines
            </a>
          </li>
          <li className="nav-items">
            <a
              href="/checksubmission"
              className="nav-links"
              style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
            >
              Check Submission
            </a>
          </li>
        </div>
      </ul>
      
      <div className="register-form-container">
        <div className="register-heading">Registration</div>
        <form
          className="register-form"
          id="register-form"
          onSubmit={e => submit(e)}
        >
          <div className="register-form-subheading">Personal Details</div>
          <div className="register-form-input-container">
            <div className="register-form-input-label">Name</div>
            <input
              required
              type="text"
              className="register-form-input"
              onChange={e => handle(e)}
              id="name"
              value={data.name}
            />
          </div>
          <div className="register-form-input-container">
            <div className="register-form-input-label">Phone</div>
            <input
              type="tel"
              className="register-form-input"
              pattern="^\d{10}$"
              required
              onChange={e => handle(e)}
              id="phone"
              value={data.phone}
            />
          </div>
          <div className="register-form-input-container">
            <div className="register-form-input-label">Email</div>
            <input
              required
              type="email"
              className="register-form-input"
              onChange={e => handle(e)}
              id="email"
              value={data.email}
            />
          </div>
          <div className="register-form-input-container">
            <div className="register-form-input-label">Branch</div>
            <input
              required
              type="text"
              className="register-form-input"
              onChange={e => handle(e)}
              id="branch"
              value={data.branch}
            />
          </div>
          <div className="register-form-input-container">
            <div className="register-form-input-label">Enrollment No.</div>
            <input
              type="tel"
              className="register-form-input"
              pattern="^\d{8}$"
              required
              onChange={e => handle(e)}
              id="enroll"
              value={data.enroll}
            />
          </div>
          <div className="register-form-subheading">Proposal</div>
          <div className="register-form-input-container">
            <div className="register-form-input-label">Proposal Link</div>
            <input
              required
              type="url"
              className="register-form-input"
              onChange={e => handle(e)}
              id="link"
              value={data.link}
            />
          </div>
          <div className="register-form-input-container">
            <div className="register-form-input-label">Type</div>
            <select
              required
              id="devdes"
              value={data.devdes}
              className="register-form-type"
              onChange={e => handle(e)}
            >
              <option disabled selected value>
                select proposal type
              </option>
              <option value="developer">Developer</option>
              <option value="design">Designer</option>
            </select>
          </div>
          <div className="register-form-subheading">
            Social &nbsp;
            <div className="register-form-subheading-subscript">(optional)</div>
          </div>
          <div className="register-form-input-container">
            <div className="register-form-input-label">Github Username</div>
            <input
              type="text"
              className="register-form-input"
              onChange={e => handle(e)}
              id="github"
              value={data.github}
            />
          </div>
          <div className="register-form-input-container">
            <div className="register-form-input-label">Dribbble</div>
            <input
              type="text"
              className="register-form-input"
              onChange={e => handle(e)}
              id="dribbble"
              value={data.dribbble}
            />
          </div>
          <div className="register-form-input-container">
            <div className="register-form-input-label">Behance</div>
            <input
              type="text"
              className="register-form-input"
              onChange={e => handle(e)}
              id="behance"
              value={data.behance}
            />
          </div>
          <div className="register-form-input-container">
            <div className="register-form-input-label">Others</div>
            <input
              type="text"
              className="register-form-input"
              onChange={e => handle(e)}
              id="others"
              value={data.others}
            />
          </div>
          <div className="register-form-footer">
            <a href="/" className="register-form-cancel">
              cancel
            </a>
            <button className="register-form-submit" type="submit">
              Register
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

const CheckRegister = () => {
  const checkUrl = URLs.BACKEND_URL+'/woc/checksubmission';
  const [data, setData] = useState({
    enroll: '',
  });

  function handle(e) {
    const newData = {};
    newData.enroll = e.target.value;
    setData(newData);
  }

  async function submit(e) {
    e.preventDefault();
    try {
      const response = await Axios.post(checkUrl, {
        enroll: data.enroll,
      });

      if (response.status === 200) {
        if (response.data === 'Registered') {
          alert('You proposal has been submitted!');
        } else {
          alert('You proposal has NOT been submitted');
        }
      } else {
        alert('There was some error, please try again.');
      }
    } catch (error) {
      alert('There was some error, please try again.');
    }
  }

  return (
    <Fragment>
      <ul className="nav-bar scrolled shadow" id="navbar-register">
        <li className="sds-logo-container">
          <a
            href="https://sdslabs.co/"
            target="_blank"
            className="js-scroll-trigger"
          >
            <img src="../../assets/images/sds-logo.svg" className="sds-logo" />
            <div className="sds-logo-colored-container">
              <img
                src="../../assets/images/sds-logo-colored.svg"
                className="sds-logo-colored"
              />
              <hr className="sds-logo-colored-vertical-line" />
              <span className="sds-logo-colored-text">WoC</span>
            </div>
          </a>
        </li>

            {/* This is where navbar is coded */}
          {/* This is where hamburger menu is coded */}
          <button className="hamburger-menu" onClick={openmenu}>
              <svg id="hamburger" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 3C1.36739 3 1.24021 3.05268 1.14645 3.14645C1.05268 3.24021 1 3.36739 1 3.5C1 3.63261 1.05268 3.75979 1.14645 3.85355C1.24021 3.94732 1.36739 4 1.5 4H13.5C13.6326 4 13.7598 3.94732 13.8536 3.85355C13.9473 3.75979 14 3.63261 14 3.5C14 3.36739 13.9473 3.24021 13.8536 3.14645C13.7598 3.05268 13.6326 3 13.5 3H1.5ZM1 7.5C1 7.36739 1.05268 7.24021 1.14645 7.14645C1.24021 7.05268 1.36739 7 1.5 7H13.5C13.6326 7 13.7598 7.05268 13.8536 7.14645C13.9473 7.24021 14 7.36739 14 7.5C14 7.63261 13.9473 7.75979 13.8536 7.85355C13.7598 7.94732 13.6326 8 13.5 8H1.5C1.36739 8 1.24021 7.94732 1.14645 7.85355C1.05268 7.75979 1 7.63261 1 7.5ZM1 11.5C1 11.3674 1.05268 11.2402 1.14645 11.1464C1.24021 11.0527 1.36739 11 1.5 11H13.5C13.6326 11 13.7598 11.0527 13.8536 11.1464C13.9473 11.2402 14 11.3674 14 11.5C14 11.6326 13.9473 11.7598 13.8536 11.8536C13.7598 11.9473 13.6326 12 13.5 12H1.5C1.36739 12 1.24021 11.9473 1.14645 11.8536C1.05268 11.7598 1 11.6326 1 11.5Z" fill="white"/>
        </svg>
              </button>
        {/* hamburger icon */}
    
        <div className="nav-div" id="navopt">
          <li className="nav-items">
            <a href="/#about" className="nav-links js-scroll-trigger" onClick={(event) => smoothScroll(event, "about")}>
              About
            </a>
          </li>
          <li className="nav-items">
            <a href="#process" className="nav-links js-scroll-trigger" onClick={(event) => smoothScroll(event, "process")}>
              Process
            </a>
          </li>
          <li className="nav-items">
            <a href="#timeline" className="nav-links js-scroll-trigger" onClick={(event) => smoothScroll(event, "timeline")}>
              Timeline
            </a>
          </li>
          <li className="nav-items">
            <a href="#guidelines" className="nav-links js-scroll-trigger" onClick={(event) => smoothScroll(event, "guidelines")}>
              Guidelines
            </a>
          </li>
          <li className="nav-items" id="register-button">
            <a href="/register" className="nav-links">
              Register
            </a>
          </li>
        </div>
      </ul>

      <div className="register-form-container">
        <div className="register-heading">Check Submission</div>
        <form
          className="register-form"
          id="register-form"
          onSubmit={e => submit(e)}
        >
          <div className="register-form-input-container">
            <div className="register-form-input-label">Enrollment No.</div>
            <input
              type="tel"
              className="register-form-input"
              pattern="^\d{8}$"
              required
              onChange={e => handle(e)}
              id="enroll"
              value={data.enroll}
            />
          </div>
          <div className="register-form-footer">
            <button className="register-form-submit" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </Fragment>
  );
};

export { Register, CheckRegister };
