import { Fragment, h } from 'preact';
import moment from 'moment';
import dates from './dates.json';

import Content from './content';
import Navbar from './navbar';
import Footer from './footer';

const Home = () => (
  <Fragment>
    <Navbar />

    <div className="intro-page scrollspy" id="intro">
      <div className="background-scene-background"></div>
      <div className="woc-heading">Winter of Code</div>
      <div className="woc-subheading">
        A month long coding & designing extravaganza
        <br /> for beginners organized by SDSLabs, IIT Roorkee
      </div>
      <img
        src="../../assets/images/horizontal-bar.svg"
        className="horizontal-bar"
      />
      <a
        href={
          moment().isBefore(dates['proposals-released'].toString())
            ? '/register'
            : '/results'
        }
        className="woc-button"
      >
        <button id="register-button" type="button">
          {moment().isBefore(dates['proposals-released'].toString())
            ? 'Register'
            : 'Results'}
        </button>
      </a>
    </div>

    <Content />

    <Footer />
  </Fragment>
);

export default Home;
