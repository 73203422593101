import { h } from 'preact';
import moment from 'moment';
import dates from './dates.json';

function smoothScroll(event, id) {
  event.preventDefault();
  document.getElementById(id).scrollIntoView({behavior: "smooth"});
}

function openmenu() {
  var x = document.getElementById("navopt");
  if (x.style.display == "flex") {
    x.style.display = "none";
  } else {
    x.style.display = "flex";
  }
}

const Navbar = () => (
  <ul className="nav-bar" id="navbar">
  <li className="sds-logo-container">
    <a
      href="https://sdslabs.co/"
      target="_blank"
      className="js-scroll-trigger"
    >
      <img src="../../assets/images/sds-logo.svg" className="sds-logo" />
      <div className="sds-logo-colored-container">
        <img
          src="../../assets/images/sds-logo-colored.svg"
          className="sds-logo-colored"
        />
        <hr className="sds-logo-colored-vertical-line" />
        <span className="sds-logo-colored-text">WoC</span>
      </div>
    </a>
  </li>
    
    {/* This is where navbar is coded */}
      {/* This is where hamburger menu is coded */}
          <button className="hamburger-menu" onClick={openmenu}>
          <svg id="hamburger" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 3C1.36739 3 1.24021 3.05268 1.14645 3.14645C1.05268 3.24021 1 3.36739 1 3.5C1 3.63261 1.05268 3.75979 1.14645 3.85355C1.24021 3.94732 1.36739 4 1.5 4H13.5C13.6326 4 13.7598 3.94732 13.8536 3.85355C13.9473 3.75979 14 3.63261 14 3.5C14 3.36739 13.9473 3.24021 13.8536 3.14645C13.7598 3.05268 13.6326 3 13.5 3H1.5ZM1 7.5C1 7.36739 1.05268 7.24021 1.14645 7.14645C1.24021 7.05268 1.36739 7 1.5 7H13.5C13.6326 7 13.7598 7.05268 13.8536 7.14645C13.9473 7.24021 14 7.36739 14 7.5C14 7.63261 13.9473 7.75979 13.8536 7.85355C13.7598 7.94732 13.6326 8 13.5 8H1.5C1.36739 8 1.24021 7.94732 1.14645 7.85355C1.05268 7.75979 1 7.63261 1 7.5ZM1 11.5C1 11.3674 1.05268 11.2402 1.14645 11.1464C1.24021 11.0527 1.36739 11 1.5 11H13.5C13.6326 11 13.7598 11.0527 13.8536 11.1464C13.9473 11.2402 14 11.3674 14 11.5C14 11.6326 13.9473 11.7598 13.8536 11.8536C13.7598 11.9473 13.6326 12 13.5 12H1.5C1.36739 12 1.24021 11.9473 1.14645 11.8536C1.05268 11.7598 1 11.6326 1 11.5Z" fill="white"/>
    </svg>
          </button>
    {/* hamburger icon */}
    
    <div className="nav-div" id="navopt">
        <li className="nav-items">
          <a href="#about" className="nav-links js-scroll-trigger" onClick={(event) => smoothScroll(event, "about")}>
            About
          </a>
        </li>
        <li className="nav-items">
          <a href="#process" className="nav-links js-scroll-trigger" onClick={(event) => smoothScroll(event, "process")}>
            Process
          </a>
        </li>
        <li className="nav-items">
          <a href="#timeline" className="nav-links js-scroll-trigger" onClick={(event) => smoothScroll(event, "timeline")}>
            Timeline
          </a>
        </li>
        <li className="nav-items">
          <a href="#guidelines" className="nav-links js-scroll-trigger" onClick={(event) => smoothScroll(event, "guidelines")}>
            Guidelines
          </a>
        </li>
        {/* <li className="nav-items" id="register-button">
          <a href="/register" className="nav-links">
            Register
          </a>
        </li> */}
        <li className="nav-items">
          <a
            href={moment().isBefore(dates['proposals-released'].toString()) ? "/register": "/results"}
            className="nav-links"
            style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
          >
            {moment().isBefore(dates['proposals-released'].toString()) ? "Register":"Results"}
          </a>
        </li>
      </div>
  

  </ul>
);

export default Navbar;
