import { h } from 'preact';
import moment from 'moment';
import dates from './dates.json';

const Content = () => (
  <div className="woc-content-container">
    <section className="about-section scrollspy" id="about">
      <img src="../../assets/images/snow-flake.svg" className="snow-flake" />
      <div className="about-heading">About</div>
      <div className="about-info">
        A month long online programme for beginners. The programme not only
        helps students to get involved in open source, but also preps them for
        many open source summer programmes, Google Summer of Code being one of
        them.
      </div>
      <div className="about-card-container">
        <div className="card">
          <div className="card-icon-container">
            <img
              src="../../assets/images/gsoc-logo.svg"
              className="card-icon"
            />
          </div>
          <div className="card-content">Introduction to GSoC Environment</div>
        </div>
        <div className="card">
          <div className="card-icon-container">
            <img
              src="../../assets/images/open-source.svg"
              className="card-icon"
            />
          </div>
          <div className="card-content">Fosters the culture of Open source</div>
        </div>
        <div className="card">
          <div className="card-icon-container">
            <img
              src="../../assets/images/direct_entry.svg"
              className="card-icon"
            />
          </div>
          <div className="card-content">
            Direct entry to SDSLabs Interviews for freshers
          </div>
        </div>
        <div className="card">
          <div className="card-icon-container">
            <img
              src="../../assets/images/mentorship.svg"
              className="card-icon"
            />
          </div>
          <div className="card-content">Mentorship from past GSoC holders</div>
        </div>
      </div>
    </section>

    <section className="process-section scrollspy" id="process">
      <img src="../../assets/images/snow-flake.svg" className="snow-flake" />

      <div className="process-heading">Process</div>
      <div className="process-container">
        <div className="process-card">
          <div className="process-card-header">
            for <span className="heading-blue">Developers</span>
          </div>
          <ul className="process-card-content">
            <li className="process-card-content-bullets">
              <img
                src="../../assets/images/idea.svg"
                className="process-card-content-custom-bullet"
              />{' '}
              Submit your idea
            </li>
            <li className="process-card-content-bullets">
              <img
                src="../../assets/images/shortlist.svg"
                className="process-card-content-custom-bullet"
              />
              Get shortlisted and connect with your mentor
            </li>
            <li className=" process-card-content-bullets">
              <img
                src="../../assets/images/code-review.svg"
                className="process-card-content-custom-bullet"
              />
              Code-review-feedback
            </li>
            <li className=" process-card-content-bullets">
              <img
                src="../../assets/images/watch.svg"
                className="process-card-content-custom-bullet"
              />
              Watch your idea get live.
            </li>
          </ul>
        </div>
        <div className="process-card">
          <div className="process-card-header">
            for <span className="heading-blue">Designers</span>
          </div>
          <ul className="process-card-content">
            <li className="process-card-content-bullets">
              <img
                src="../../assets/images/idea.svg"
                className="process-card-content-custom-bullet"
              />{' '}
              Submit your idea
            </li>
            <li className="process-card-content-bullets">
              <img
                src="../../assets/images/shortlist.svg"
                className="process-card-content-custom-bullet"
              />
              Get shortlisted and connect with your mentor
            </li>
            <li className=" process-card-content-bullets">
              <img
                src="../../assets/images/code-review.svg"
                className="process-card-content-custom-bullet"
              />
              Brainstorm and Design
            </li>
            <li className=" process-card-content-bullets">
              <img
                src="../../assets/images/watch.svg"
                className="process-card-content-custom-bullet"
              />
              Watch your idea turn into solution
            </li>
          </ul>
        </div>
      </div>
    </section>

    <section className="timeline-section scrollspy" id="timeline">
      <div className="timeline-heading">Timeline</div>
      <img src="../../assets/images/skiing.svg" className="skiing" />
      <div className="timeline-content-container">
        <div
          className="timeline-content-card-horizontal-line"
          id="timeline-horizontal-line"
        ></div>

        <div
          className={
            moment().isBefore(dates['woc-announced'].toString())
              ? 'timeline-content-card-container'
              : moment().isSameOrAfter(dates['woc-announced'].toString()) &&
                moment().isBefore(dates['proposal-deadline'].toString())
              ? 'timeline-content-card-container active'
              : 'timeline-content-card-container disabled'
          }
        >
          <div className="timeline-content-card-tick-container">
            <img
              src="../../assets/images/tick.svg"
              className="timeline-content-card-tick"
            />
          </div>
          <div className="timeline-content-card-heading">
            Winter of Code Announced
          </div>
          <div className="timeline-content-card-line-container">
            <hr className="timeline-content-card-vertical-line" />
          </div>
          <div className="timeline-content-card-date-container">
            <div className="timeline-content-card-date">
              {moment(dates['woc-announced'].toString()).format('Do MMM')}
            </div>
            <div className="timeline-content-card-year">
              {moment(dates['woc-announced'].toString()).format('YYYY')}
            </div>
          </div>
        </div>

        <div
          className={
            moment().isBefore(dates['proposal-deadline'].toString())
              ? 'timeline-content-card-container'
              : moment().isSameOrAfter(dates['proposal-deadline'].toString()) &&
                moment().isBefore(dates['proposals-released'].toString())
              ? 'timeline-content-card-container active'
              : 'timeline-content-card-container disabled'
          }
        >
          <div className="timeline-content-card-tick-container">
            <img
              src="../../assets/images/tick.svg"
              className="timeline-content-card-tick"
            />
          </div>
          <div className="timeline-content-card-heading">
            Proposal Submission Deadline
          </div>
          <div className="timeline-content-card-line-container">
            <hr className="timeline-content-card-vertical-line" />
          </div>
          <div className="timeline-content-card-date-container">
            <div className="timeline-content-card-date">
              {moment(dates['proposal-deadline'].toString()).format('Do MMM')}
            </div>
            <div className="timeline-content-card-year">
              {moment(dates['proposal-deadline'].toString()).format('YYYY')}
            </div>
          </div>
        </div>

        <div
          className={
            moment().isBefore(dates['proposals-released'].toString())
              ? 'timeline-content-card-container'
              : moment().isSameOrAfter(
                  dates['proposals-released'].toString()
                ) && moment().isBefore(dates['initial-feedback'].toString())
              ? 'timeline-content-card-container active'
              : 'timeline-content-card-container disabled'
          }
        >
          <div className="timeline-content-card-tick-container">
            <img
              src="../../assets/images/tick.svg"
              className="timeline-content-card-tick"
            />
          </div>
          <div className="timeline-content-card-heading">
            List of Accepted Proposals released
          </div>
          <div className="timeline-content-card-line-container">
            <hr className="timeline-content-card-vertical-line" />
          </div>
          <div className="timeline-content-card-date-container">
            <div className="timeline-content-card-date">
              {moment(dates['proposals-released'].toString()).format('Do MMM')}
            </div>
            <div className="timeline-content-card-year">
              {moment(dates['proposals-released'].toString()).format('YYYY')}
            </div>
          </div>
        </div>

        <div
          className={
            moment().isBefore(dates['initial-feedback'].toString())
              ? 'timeline-content-card-container'
              : moment().isSameOrAfter(dates['initial-feedback'].toString()) &&
                moment().isBefore(dates['mid-feedback'].toString())
              ? 'timeline-content-card-container active'
              : 'timeline-content-card-container disabled'
          }
        >
          <div className="timeline-content-card-tick-container">
            <img
              src="../../assets/images/tick.svg"
              className="timeline-content-card-tick"
            />
          </div>
          <div className="timeline-content-card-heading">
            Initial Feedback & Connect
          </div>
          <div className="timeline-content-card-line-container">
            <hr className="timeline-content-card-vertical-line" />
          </div>
          <div className="timeline-content-card-date-container">
            <div className="timeline-content-card-date">
              {moment(dates['initial-feedback'].toString()).format('Do MMM')}
            </div>
            <div className="timeline-content-card-year">
              {moment(dates['initial-feedback'].toString()).format('YYYY')}
            </div>
          </div>
        </div>

        <div
          className={
            moment().isBefore(dates['mid-feedback'].toString())
              ? 'timeline-content-card-container'
              : moment().isSameOrAfter(dates['mid-feedback'].toString()) &&
                moment().isBefore(dates['final-feedback'].toString())
              ? 'timeline-content-card-container active'
              : 'timeline-content-card-container disabled'
          }
        >
          <div className="timeline-content-card-tick-container">
            <img
              src="../../assets/images/tick.svg"
              className="timeline-content-card-tick"
            />
          </div>
          <div className="timeline-content-card-heading">
            Mid Evaluation & Connect
          </div>
          <div className="timeline-content-card-line-container">
            <hr className="timeline-content-card-vertical-line" />
          </div>
          <div className="timeline-content-card-date-container">
            <div className="timeline-content-card-date">
              {moment(dates['mid-feedback'].toString()).format('Do MMM')}
            </div>
            <div className="timeline-content-card-year">
              {moment(dates['mid-feedback'].toString()).format('YYYY')}
            </div>
          </div>
        </div>

        <div
          className={
            moment().isBefore(dates['final-feedback'].toString())
              ? 'timeline-content-card-container'
              : moment().isSameOrAfter(dates['final-feedback'].toString()) &&
                moment().isBefore('2024-01-01'.toString())
              ? 'timeline-content-card-container active'
              : 'timeline-content-card-container disabled'
          }
        >
          <div className="timeline-content-card-tick-container">
            <img
              src="../../assets/images/tick.svg"
              className="timeline-content-card-tick"
            />
          </div>
          <div className="timeline-content-card-heading">
            Final Evaluation & Connect
          </div>
          <div className="timeline-content-card-line-container">
            <hr className="timeline-content-card-vertical-line" />
          </div>
          <div className="timeline-content-card-date-container">
            <div className="timeline-content-card-date">
              {moment(dates['final-feedback'].toString()).format('Do MMM')}
            </div>
            <div className="timeline-content-card-year">
              {moment(dates['final-feedback'].toString()).format('YYYY')}
            </div>
          </div>
        </div>
        <div className="timeline-content-card-container free-space-container"></div>
      </div>
    </section>

    <section className="proposal-section scrollspy" id="guidelines">
      <img src="../../assets/images/compass.svg" className="compass" />
      <div className="proposal-heading">Proposal Guidelines</div>
      <div className="proposal-content-container">
        <div className="proposal-content-card">
          <p className="proposal-content-card-paragraph">
            A good proposal is well formatted and easy to read for the
            reviewers. It must be precise and contain relevant details while
            avoiding any extra information.
          </p>
          <p className="proposal-content-card-paragraph">
            You must clearly take care of the following points in your proposal
            -{' '}
          </p>
          <ul className="proposal-content-card-bullet-container">
            <li className="proposal-content-card-bullet">Description</li>
            <li className="proposal-content-card-bullet">Use Cases</li>
            <li className="proposal-content-card-bullet">Impact</li>
            <li className="proposal-content-card-bullet">Timelines</li>
            <li className="proposal-content-card-bullet">
              Context (with respect to SDSLabs)
            </li>
          </ul>
          <p className="proposal-content-card-paragraph">
            While describing the timeline, be very clear about specific goals
            you will be completing during this one month in a weekly fashion.{' '}
          </p>
          <p className="proposal-content-card-paragraph">
            While we encourage fresh and innovative ideas from you people,
            it&apos;s upto you to make sure that you select a realistic goal
            considering the time duration of one month.
          </p>
        </div>
        <div className="proposal-content-card" id="proposal">
          <p className="proposal-content-card-heading" id="proposal">
            Sample Proposals
          </p>
          <div className="proposal-content-card-proposal-link-container">
            <div className="proposal-content-card-proposal-link">
              <a
                target="_blank"
                href="https://drive.google.com/drive/folders/1lnC5whABnh2dT7nIXAPO8hriBMIKhjG_?usp=sharing"
                className="proposal-content-card-proposal-link"
              >
                For Developers{' '}
                <img
                  src="../../assets/images/right-arrow.svg"
                  className="right-arrow"
                />
              </a>
            </div>
            <div className="proposal-content-card-proposal-link">
              <a
                target="_blank"
                href="https://drive.google.com/drive/folders/1Ga2QVNxIC0vLNp3Lt6E1byg9YeCQca-B?usp=sharing"
                className="proposal-content-card-proposal-link"
              >
                For Designers{' '}
                <img
                  src="../../assets/images/right-arrow.svg"
                  className="right-arrow"
                />
              </a>
            </div>
          </div>
          <p className="proposal-content-card-paragraph">
            Proposals for previous years&apos; GSoC participants can be viewed{' '}
            <a target="_blank" href="https://blog.sdslabs.co/gsoc/">
              here
            </a>
          </p>
        </div>
      </div>
    </section>
  </div>
);

export default Content;
