import { Fragment, h } from 'preact';

const Results = () => (
  <Fragment>
    <ul className="nav-bar scrolled shadow" id="navbar-register">
      <li className="sds-logo-container">
        <a href="/#intro" className="js-scroll-trigger">
          <div className="sds-logo-colored-container">
            <img
              src="../../assets/images/sds-logo-colored.svg"
              className="sds-logo-colored"
            />
            <hr className="sds-logo-colored-vertical-line" />
            <span className="sds-logo-colored-text">WoC</span>
          </div>
        </a>
      </li>

      <div className="nav-div">
        <li className="nav-items">
          <a href="/#about" className="nav-links js-scroll-trigger">
            About
          </a>
        </li>
        <li className="nav-items">
          <a href="/#process" className="nav-links js-scroll-trigger">
            Process
          </a>
        </li>
        <li className="nav-items">
          <a href="/#timeline" className="nav-links js-scroll-trigger">
            Timeline
          </a>
        </li>
        <li className="nav-items">
          <a href="/#guidelines" className="nav-links js-scroll-trigger">
            Guidelines
          </a>
        </li>
        <li className="nav-items">
          <a href="/results" className="nav-links">
            Results
          </a>
        </li>
        {/* <li className="nav-items">
          <a
            href="/checksubmission"
            className="nav-links"
            style={{ paddingTop: '0.5rem', paddingBottom: '0.5rem' }}
          >
            Check Submission
          </a>
        </li> */}
      </div>
    </ul>
    <div className="result-container">
      <div className="result-heading">Selected WoC students</div>
      <div className="result-content">
        <div className="result-subheading">Developers</div>
        <ul className="result-bullet-container">
          <li className="result-bullet">Aaditya Gupta (GPT)</li>
          <li className="result-bullet">Angel Sharma (MnC)</li>
          <li className="result-bullet">Arpan Mahanty (EE)</li>
          <li className="result-bullet">Karthik Ayangar (EE)</li>
          <li className="result-bullet">Khushal Agrawal (CSE)</li>
          <li className="result-bullet">Lakshya Shishir Khandelwal (GT)</li>
          <li className="result-bullet">Mankirat Singh (EE)</li>
          <li className="result-bullet">Rishi Gupta (EE)</li>
          <li className="result-bullet">Saurabh Rana (BSBE)</li>
          <li className="result-bullet">Shree Singhi (DSAI)</li>
        </ul>
        <div className="result-subheading">Designers</div>
        <ul className="result-bullet-container">
          <li className="result-bullet">Aarushi Gupta (GT)</li>
          <li className="result-bullet">Abhijeet Dixit (CE)</li>
          <li className="result-bullet">Anshika Arora (Eco)</li>
          <li className="result-bullet">Saksham Jain (AR)</li>
          <li className="result-bullet">Anirudh Deol (CH 2Y)</li>
        </ul>
        <div className="result-paragraph">
          We had a tough time selecting the best proposals out of all the
          amazing 100+ entries. If your proposal wasn&apos;t able to make it in,
          don&apos;t fret. Your proposal could use some more research about the
          project. You can still develop your project as a submission for
          SDSLabs&apos; Makers. The selected students have been contacted via
          email by their mentors.
        </div>
      </div>
    </div>
  </Fragment>
);

export default Results;
